import {
  Button,
  DialogActions,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from '@reach/router';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Modal from '../../components/Modal';
import {
  extendCurrentOrder as extendCurrentOrderQuery,
  getPriceForFollowingOrderQuery,
  loadOrders,
} from '../../graphql/queries';
import {
  GetPriceForFollowingOrderQuery,
  GetPriceForFollowingOrderQueryVariables,
  GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder,
  extendCurrentOrder as extendCurrentOrderType,
  extendCurrentOrderVariables,
} from '../../graphql/types';
import { InputOrderType, OrderType } from '../../lib/types';
import { getGraphqlErrorMessage } from '../../lib/utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  order: OrderType;
  loadOrdersVariables: any;
}
interface IState {
  order: InputOrderType;
  dateDisabled: boolean;
  season: boolean[];
  useSeasonPrices: boolean;
}

/**
 * ExtendOrderModal 
 * Component for opened modal used for extending an end date of orders.
 *
 * Available for users with role Admin, Owner and Employee.
 * Available for NOT seasonal orders and with order state Active.
 * 
 * Opened modal contains date picker for an end date.
 * - By default: an end date will be automatically set as the day after of the previous end date
 * 
 * After choosing the date, conflict orders will be checked for the date chosen.
 * Conflict orders will be displayed if any occured.
 * Price will be recalculated and displayed on date change.
 * Confirming the extension of the order will trigger "extendCurrentOrder" GQL mutation
 */

const ExtendOrderModal = ({
  isOpen,
  onClose,
  order,
  loadOrdersVariables,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [totalPrice, setTotalPrice] = useState({ bailSum: 0, priceSum: 0 });

  const dateFrom = moment(order.to).add(1, 'd');
  const dateTo = moment(order.to).add(1, 'd');

  const formatDateForTextField = (date: string) => {
    const fromDate = new Date(date);
    const year = fromDate.getFullYear();
    const month = String(fromDate.getMonth() + 1).padStart(2, '0');
    const day = String(fromDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getPrice = (itemId?: number) => {
    if (!itemId) return 0;
    return followingPrices?.priceForFollowingOrder?.setsInOrder?.find(
      item => item?.setId === itemId,
    )?.price;
  };

  const getBail = (itemId?: number) => {
    if (!itemId) return 0;
    return followingPrices?.priceForFollowingOrder?.setsInOrder?.find(
      item => item?.setId === itemId,
    )?.bail;
  };

  const calculateTotal = (
    obj?:
      | (GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder | null)[]
      | null,
  ) => {
    let bailSum = 0;
    let priceSum = 0;
    if (obj) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i]) {
          bailSum += obj[i]?.bail || 0;
          priceSum += obj[i]?.price || 0;
        }
      }
    }

    return { bailSum, priceSum };
  };

  const [state, setState] = useState<IState>({
    order: {
      from: dateFrom.format('YYYY-MM-DD'),
      to: dateTo.format('YYYY-MM-DD'),
      season: order.season,
      customer: {
        name: '',
        phone: '',
        id: '',
      },
      note: '',
    },
    dateDisabled: false,
    season: [false, false],
    useSeasonPrices: false,
  });

  const { data: followingPrices } = useQuery<
    GetPriceForFollowingOrderQuery,
    GetPriceForFollowingOrderQueryVariables
  >(getPriceForFollowingOrderQuery, {
    variables: {
      from: moment(order.from)
        .add(1, 'd')
        .utc(true)
        .startOf('day'),
      to: moment(state.order.to)
        .utc(true)
        .endOf('day'),
      orderId: order.id,
      season: false,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: conflictOrders } = useQuery<
    GetPriceForFollowingOrderQuery,
    GetPriceForFollowingOrderQueryVariables
  >(getPriceForFollowingOrderQuery, {
    variables: {
      from: moment(state.order.from)
        .utc(true)
        .startOf('day'),
      to: moment(state.order.to)
        .utc(true)
        .endOf('day'),
      orderId: order.id,
      season: false,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (followingPrices) {
      const setsInOrder = followingPrices.priceForFollowingOrder?.setsInOrder;
      const { priceSum } = calculateTotal(setsInOrder);
      setTotalPrice({ bailSum: 0, priceSum: priceSum - order.price });
    }
  }, [followingPrices, order.price]);

  const [extendCurrentOrder] = useMutation<
    extendCurrentOrderType,
    extendCurrentOrderVariables
  >(extendCurrentOrderQuery, {
    onError: err =>
      enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
  });

  // TODO: make simpler func for DATE TO only
  const handleChangeDate = (name: string) => (event: {
    target: { value: string };
    persist: any;
  }) => {
    event.persist();
    const date = event.target.value;

    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [name]: date,
      },
    }));
  };

  const handleSave = async () => {
    await extendCurrentOrder({
      variables: {
        to: state.order.to,
        orderId: order.id,
        price: order.price + totalPrice.priceSum,
      },
      refetchQueries: [{ query: loadOrders, variables: loadOrdersVariables }],
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Prodloužení objednávky">
      <Grid container>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Od"
            type="date"
            required={true}
            value={formatDateForTextField(state.order.from)}
            className="text-field"
            disabled={true}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: formatDateForTextField(dateFrom.toString()),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Do"
            type="date"
            required={true}
            value={formatDateForTextField(state.order.to)}
            className="text-field"
            onChange={handleChangeDate('to')}
            disabled={state.dateDisabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: formatDateForTextField(dateFrom.toString()),
            }}
          />
        </Grid>
        <Table>
          <TableBody>
            {!!order.sets?.length && (
              <TableRow>
                <TableCell align="center">Předmět nájmu</TableCell>
                <TableCell align="center">Cena</TableCell>
                <TableCell align="center">Kauce</TableCell>
              </TableRow>
            )}
            {order.sets?.map((set, setIdx) => (
              <Fragment key={set?.id || setIdx}>
                <TableRow>
                  <TableCell className={classes.boldCell}>
                    {set?.category}
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {getPrice(set?.id)?.toLocaleString('FR')} Kč
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {getBail(set?.id)?.toLocaleString('FR')} Kč
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
            <TableRow>
              <TableCell>Celkem doplatek</TableCell>
              <TableCell className={classes.boldCell} align="center">
                {totalPrice.priceSum?.toLocaleString('FR')} Kč
              </TableCell>
              <TableCell className={classes.boldCell} align="center">
                {totalPrice.bailSum?.toLocaleString('FR')} Kč
              </TableCell>
            </TableRow>
            <Table></Table>
          </TableBody>
        </Table>
        {!!conflictOrders?.priceForFollowingOrder.conflictOrders?.length && (
          <>
            <Divider />
            <Typography
              variant="h6"
              style={{ marginInline: 'auto', marginTop: '2rem' }}
            >
              Konfliktní objednávky
            </Typography>
            <div className={classes.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Od</TableCell>
                    <TableCell>Do</TableCell>
                    <TableCell>Zákazník</TableCell>
                  </TableRow>
                  {conflictOrders.priceForFollowingOrder.conflictOrders.map(
                    (order, idx) => (
                      <Fragment key={order?.id || idx}>
                        <TableRow>
                          <TableCell>
                            <Link to={`order-detail/${order?.id}`}>
                              {order?.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {new Date(order?.from).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {new Date(order?.to).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{order?.customer.name}</TableCell>
                        </TableRow>
                      </Fragment>
                    ),
                  )}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" onClick={handleSave} color="primary">
            Vytvořit
          </Button>
          <Button onClick={onClose} color="primary">
            Zavřít
          </Button>
        </DialogActions>
      </Grid>
    </Modal>
  );
};

export default ExtendOrderModal;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    table: {
      maxHeight: '300px',
      overflow: 'auto',
      width: '100%',
    },
    dialogActions: {
      marginInline: 'auto',
      marginTop: '2rem',
    },
    tableCell: {
      border: 'none',
      padding: '4px 4px 4px 8px',
    },
    boldCell: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: 'bold',
    },
  }),
);
