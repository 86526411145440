import {
  Button,
  DialogActions,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from '@reach/router';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import CheckBoxCustom from '../../components/CheckBox';
import Modal from '../../components/Modal';
import {
  createFollowingOrder as createFollowingOrderQuery,
  getPriceForFollowingOrderQuery,
  isOrderSeasonPriceDisabled,
  loadOrders,
} from '../../graphql/queries';
import {
  GetPriceForFollowingOrderQuery,
  GetPriceForFollowingOrderQueryVariables,
  GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder,
  IsOrderSeasonPriceDisabled,
  IsOrderSeasonPriceDisabledVariables,
  createFollowingOrder as createFollowingOrderType,
  createFollowingOrderVariables,
} from '../../graphql/types';
import { labelPlacement } from '../../lib/enums';
import { InputOrderType, OrderType } from '../../lib/types';
import { getGraphqlErrorMessage } from '../../lib/utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  order: OrderType;
  loadOrdersVariables: any;
}
interface IState {
  order: InputOrderType;
  dateDisabled: boolean;
  season: boolean[];
  useSeasonPrices: boolean;
}

/**
 * FollowingOrderModal
 * Component for opened modal used for re-creating order with the same items.
 * 
 * Opened modal contains date picker with checkboxes for seasonal order or seasonal prices.
 * By default: Date from is set to current date and is disabled for any changes.
 * Choosing seasonal order disables the end date picker.
 * Price is recalculated on the date change.
 * After choosing the date, conflict orders will be checked.
 * Conflict orders will be displayed if any occured.
 * Confirming the creation of the order will trigger "createFollowingOrder" GQL mutation
 */


const FollowingOrderModal = ({
  isOpen,
  onClose,
  order,
  loadOrdersVariables,
}: Props) => {
  const getDateTo = (fromDate: Moment, toDate: Moment): Moment => {
    if (fromDate.isBefore(moment()) && toDate.isBefore(moment())) {
      const daysDiff = toDate.diff(fromDate, 'days');
      const newDate = moment().add(daysDiff, 'days');

      return moment(newDate.format());
    } else {
      const daysDiff = toDate.diff(fromDate, 'days');
      const newDate = moment().add(Math.abs(daysDiff), 'days');

      return moment(newDate);
    }
  };

  const classes = useStyles();
  const [totalPrice, setTotalPrice] = useState({ bailSum: 0, priceSum: 0 });
  const [dateFrom, setDateFrom] = useState(moment());
  const [dateTo, setDateTo] = useState(
    getDateTo(moment(order.from), moment(order.to)),
  );

  const getYearDiff = (dateFrom: string, dateTo: string) => {
    const fromDate = moment(dateFrom);
    const toDate = moment(dateTo);

    return toDate.diff(fromDate, 'years');
  };
  const [state, setState] = useState<IState>({
    order: {
      from: dateFrom.format('YYYY-MM-DD'),
      to: dateTo.format('YYYY-MM-DD'),
      season: order.season,
      customer: {
        name: '',
        phone: '',
        id: '',
      },
      note: '',
    },
    dateDisabled: false,
    season: [false, false],
    useSeasonPrices: order.season,
  });

  const { data: followingPrices } = useQuery<
    GetPriceForFollowingOrderQuery,
    GetPriceForFollowingOrderQueryVariables
  >(getPriceForFollowingOrderQuery, {
    variables: {
      from: dateFrom.utc(true).startOf('day'),
      to: dateTo.utc(true).endOf('day'),
      orderId: order.id,
      season: state.order.season,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { data: isSeasonPriceDisabled } = useQuery<
    IsOrderSeasonPriceDisabled,
    IsOrderSeasonPriceDisabledVariables
  >(isOrderSeasonPriceDisabled, {
    variables: {
      orderId: order.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const { enqueueSnackbar } = useSnackbar();
  const { season } = state;

  const calculateTotal = (
    obj?:
      | (GetPriceForFollowingOrderQuery_priceForFollowingOrder_setsInOrder | null)[]
      | null,
  ) => {
    let bailSum = 0;
    let priceSum = 0;
    if (obj) {
      for (let i = 0; i < obj.length; i++) {
        if (obj[i]) {
          bailSum += obj[i]?.bail || 0;
          priceSum += obj[i]?.price || 0;
        }
      }
    }

    return { bailSum, priceSum };
  };

  useEffect(() => {
    setTotalPrice(
      calculateTotal(followingPrices?.priceForFollowingOrder?.setsInOrder),
    );
  }, [followingPrices]);

  const [createFollowingOrder] = useMutation<
    createFollowingOrderType,
    createFollowingOrderVariables
  >(createFollowingOrderQuery, {
    onError: err =>
      enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
  });

  const handleChangeDate = (name: 'from' | 'to') => (event: {
    target: { value: string };
    persist: any;
  }) => {
    event.persist();
    const date = event.target.value;

    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
        [name]: date,
      },
    }));
    if (name === 'from') {
      setDateFrom(moment(date));
      if (moment(date).isAfter(state.order.to)) {
        setDateTo(moment(date).add(1, 'd'));
      }
      if (state.order.season) {
        if (state.season[0] && !state.season[1]) {
          setDateTo(moment(date).add(1, 'y'));
        } else if (!state.season[0] && state.season[1]) {
          setDateTo(moment(date).add(2, 'y'));
        }
      }
    } else {
      if (moment(date).isBefore(state.order.from)) {
        setDateFrom(moment(date));
      }
      setDateTo(moment(date));
    }

    setState(prevState => ({
      ...prevState,
      order: {
        ...prevState.order,
      },
      season:
        getYearDiff(
          name === 'from' ? date : dateFrom.toString(),
          name === 'to' ? date : dateTo.toString(),
        ) === 2
          ? [false, true]
          : getYearDiff(
              name === 'from' ? date : dateFrom.toString(),
              name === 'to' ? date : dateTo.toString(),
            ) === 1
          ? [true, false]
          : [false, false],
    }));
  };

  const handleUseSeasonPricesChange = () => {
    setState(prevState => ({
      ...prevState,
      useSeasonPrices: !prevState.useSeasonPrices,
      order: {
        ...prevState.order,
        season: !prevState.order.season,
      },
    }));
  };

  const handleCheckboxChange = (index: number) => {
    if (isSeasonPriceDisabled?.isOrderSeasonPriceDisabled) {
      return;
    }
    const { season, order } = state;
    season[index] = !season[index];

    let isDateDisabled = false;

    if (season[index]) {
      season[Math.abs(index - 1)] = false;
      isDateDisabled = true;
    }
    const result = season.reduce((acc, curr) => acc || curr, false);

    if (result) {
      order.from = moment(order.from).isAfter(moment())
        ? moment(order.from).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
      order.to = moment(order.from)
        .add(index + 1, 'y')
        .format('YYYY-MM-DD');
    }
    setState(prevState => ({
      ...prevState,
      season: season,
      dateDisabled: isDateDisabled,
      order: {
        ...prevState.order,
        from: order.from,
        to: order.to,
      },
    }));

    setDateFrom(moment(state.order.from));
    setDateTo(moment(state.order.to));
  };

  const handleSave = async () => {
    await createFollowingOrder({
      variables: {
        from: state.order.from,
        to: state.order.to,
        orderId: order.id,
        season: state.order.season,
      },
      refetchQueries: [{ query: loadOrders, variables: loadOrdersVariables }],
    });
    onClose();
  };

  const getPrice = (itemId?: number) => {
    if (!itemId) return 0;
    return followingPrices?.priceForFollowingOrder?.setsInOrder?.find(
      item => item?.setId === itemId,
    )?.price;
  };

  const getBail = (itemId?: number) => {
    if (!itemId) return 0;
    return followingPrices?.priceForFollowingOrder?.setsInOrder?.find(
      item => item?.setId === itemId,
    )?.bail;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Návazná rezervace">
      <Grid container>
        <Grid container>
          <div className="orderDetails" style={{ marginTop: '40px' }}>
            {Object.keys(season).map((key, index) => (
              <CheckBoxCustom
                key={index}
                disabled={isSeasonPriceDisabled?.isOrderSeasonPriceDisabled}
                checked={season[index]}
                onChange={() => handleCheckboxChange(index)}
                name={index === 0 ? 'Jedna sezóna' : 'Dvě sezóny'}
                labelPlacement={labelPlacement.END}
              />
            ))}
            <CheckBoxCustom
              checked={state.order.season}
              disabled={isSeasonPriceDisabled?.isOrderSeasonPriceDisabled}
              onChange={handleUseSeasonPricesChange}
              name={'Použít sezóní ceny'}
              labelPlacement={labelPlacement.END}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Od"
            type="date"
            required={true}
            value={dateFrom.format('YYYY-MM-DD')}
            className="text-field"
            onChange={handleChangeDate('from')}
            disabled={true}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment(order.to).format('YYYY-MM-DD'),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Do"
            type="date"
            required={true}
            value={dateTo.format('YYYY-MM-DD')}
            className="text-field"
            onChange={handleChangeDate('to')}
            disabled={state.dateDisabled}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: moment(state.order.from)
                .add(1, 'd')
                .format('YYYY-MM-DD'),
            }}
          />
        </Grid>
        <Table>
          <TableBody>
            {!!order.sets?.length && (
              <TableRow>
                <TableCell align="left">Předmět nájmu</TableCell>
                <TableCell align="center">Cena</TableCell>
                <TableCell align="center">Kauce</TableCell>
              </TableRow>
            )}
            {order.sets?.map((set, setIdx) => (
              <Fragment key={set?.id || setIdx}>
                <TableRow>
                  <TableCell className={classes.boldCell}>
                    {set?.category}
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {getPrice(set?.id)?.toLocaleString('FR')} Kč
                  </TableCell>
                  <TableCell className={classes.boldCell} align="center">
                    {getBail(set?.id)?.toLocaleString('FR')} Kč
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
            <TableRow>
              <TableCell>Celkem</TableCell>
              <TableCell className={classes.boldCell} align="center">
                {totalPrice.priceSum?.toLocaleString('FR')} Kč
              </TableCell>
              <TableCell className={classes.boldCell} align="center">
                {totalPrice.bailSum?.toLocaleString('FR')} Kč
              </TableCell>
            </TableRow>
            <Table></Table>
          </TableBody>
        </Table>
        {!!followingPrices?.priceForFollowingOrder.conflictOrders?.length && (
          <>
            <Divider />
            <Typography
              variant="h6"
              style={{ marginInline: 'auto', marginTop: '2rem' }}
            >
              Konfliktní objednávky
            </Typography>
            <div className={classes.table}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Od</TableCell>
                    <TableCell>Do</TableCell>
                    <TableCell>Zákazník</TableCell>
                  </TableRow>
                  {followingPrices.priceForFollowingOrder.conflictOrders.map(
                    (order, idx) => (
                      <Fragment key={order?.id || idx}>
                        <TableRow>
                          <TableCell>
                            <Link to={`order-detail/${order?.id}`}>
                              {order?.id}
                            </Link>
                          </TableCell>
                          <TableCell>
                            {new Date(order?.from).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {new Date(order?.to).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{order?.customer.name}</TableCell>
                        </TableRow>
                      </Fragment>
                    ),
                  )}
                  <TableRow></TableRow>
                </TableBody>
              </Table>
            </div>
          </>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button variant="contained" onClick={handleSave} color="primary">
            Vytvořit
          </Button>
          <Button onClick={onClose} color="primary">
            Zavřít
          </Button>
        </DialogActions>
      </Grid>
    </Modal>
  );
};

export default FollowingOrderModal;

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    table: {
      maxHeight: '300px',
      overflow: 'auto',
      width: '100%',
    },
    dialogActions: {
      marginInline: 'auto',
      marginTop: '2rem',
    },
    tableCell: {
      border: 'none',
      padding: '4px 4px 4px 8px',
    },
    boldCell: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: 'bold',
    },
  }),
);
