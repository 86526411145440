import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { RouteComponentProps, useParams } from '@reach/router';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from 'react-apollo';
import Modal from '../../components/Modal';
import {
  canCatalogItemBeInSet,
  changeOrder,
  findStockItem,
  findStockItemPriceInterval,
  findStockItemsLike,
  findStockPairItemsLike,
  getOrder,
  getPricingForInterval,
  getSetPrices,
} from '../../graphql/queries';
import {
  CanCatalogItemBeInSet,
  CanCatalogItemBeInSetVariables,
  ChangeOrder,
  ChangeOrderVariables,
  FindStockItemPriceInterval as FindStockItemPriceIntervalQuery,
  FindStockItemPriceIntervalVariables,
  FindStockItem as FindStockItemQuery,
  FindStockItemVariables,
  FindStockItemsLike as FindStockItemsLikeQuery,
  FindStockItemsLikeVariables,
  FindStockPairItemsLike as FindStockPairItemsLikeQuery,
  FindStockPairItemsLikeVariables,
  GetOrder,
  GetOrderVariables,
  GetPricingForInterval,
  GetPricingForIntervalVariables,
  GetSetPrices,
  OrderChangeSet,
  OrderChangeSets,
} from '../../graphql/types';
import { getGraphqlErrorMessage, notEmpty } from '../../lib/utils';

interface SetCatalogItem {
  id: number;
  name: string;
  variant: string;
  producer: string;
  categoryName: string | null;
  size: string;
  createSet?: boolean | null;
  pair?: boolean | null;
}

interface SetlessItemType {
  id: number;
  code: string;
  name: string;
  variant: string;
  producer: string;
  categoryName: string;
  size: string;
  priceForInterval: number;
  bailForInterval: number;
  pair?: boolean | null;
}

type SetsPricesType = {
  [key: number]: {
    prices: number[];
    bails: number[];
  };
};

  /**
   * OrderChange
   * Page component responsible for interface to modify orders.
   * Available for users with role Admin and Employee.
   * Available for orders in state Active and Prepared.
   * 
   * Displays information about the order and provides functionality to add, delete, or modify items within the order.
   * 
   * Items are being added by 'item code'.
   * Items can be added as single setless item or as item in set.
   * Pair items are added as follows:
   * - Add first item with a code
   * - Add second item using text field in first item row
   * 
   * Confirming changes on the order will trigger "changeOrder" GQL mutation
   */

const OrderChange: React.FunctionComponent<RouteComponentProps> = () => {
  const params = useParams();
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [isLocalyChanged, setIsLocalyChanged] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [isSubmitSetLessDisabled, setIsSubmitSetLessDisabled] = useState<
    boolean
  >(false);
  const [modalItemOpen, setModalItemOpen] = useState({});
  const [modalSetOpen, setModalSetOpen] = useState<boolean>(false);
  const [modalSetlessItemOpen, setModalSetlessItemOpen] = useState<boolean>(
    false,
  );
  const [modalSetValue, setModalSetValue] = useState<string>('');
  const [modalStockCode, setModalStockCode] = useState<string>('');
  const [pairStockCode, setPairStockCode] = useState<string>('');
  const [errorState, setErrorState] = useState<{
    error: boolean;
    helperText: string | null;
  }>({
    error: false,
    helperText: null,
  });
  const [setsItems, setSetsItems] = useState<SetCatalogItem[][]>();
  const [setlessItems, setSetlessItems] = useState<SetlessItemType[]>([]);
  const [likeStockCodes, setLikeStockCodes] = useState<(string | undefined)[]>(
    [],
  );
  const [likePairCodes, setLikePairCodes] = useState<(string | undefined)[]>(
    [],
  );
  const currentSetAddIndex = useRef<number>(-1);
  const currentSetAddId = useRef<number>(-1);
  const [itemToAdd, setItemToAdd] = useState<SetCatalogItem | undefined>();
  const [totalPrice, setTotalPrice] = useState({ bailSum: 0, priceSum: 0 });
  const [setsPrices, setSetsPrices] = useState<SetsPricesType>({});
  const orderId = Number(params.orderId);
  const invalidOrderId = isNaN(orderId);
  const data = useQuery<GetOrder, GetOrderVariables>(getOrder, {
    variables: {
      id: orderId,
    },
    skip: invalidOrderId,
    onCompleted: response => {
      if (order) {
        const modalStates = {};
        if (orderSets) {
          orderSets.forEach((set, index) => {
            modalStates[index] = false;
          });
        }
        setModalItemOpen(modalStates);

        const newSets = (order.sets || []).reduce((acc: OrderChangeSet[], set) => {
          if (set.items && set.items.length !== 1) {
            acc.push({
              id: set.id,
              setPriceName: set.category || '',
              stockCodes:
                set.items.map(item => {
                  if (item.secondItem) {
                    return [item.firstItem.code, item.secondItem.code];
                  } else {
                    return [item.firstItem.code];
                  }
                }) || [],
              changed: false,
            });
          }
          return acc;
        }, []);

        const newSetless = (order.sets || [])
          .filter(set => set.items && set.items.length === 1)
          .map(set => {
            if (set && set.items) {
              const codes = [set?.items?.[0].firstItem.code];
              if (set?.items[0].secondItem) {
                codes.push(set?.items[0].secondItem.code);
              }
              return codes;
            } else {
              return [];
            }
          }) || [];

        setState({
          sets: newSets,
          setsToRemove: [],
          setlessItems: newSetless,

        });

        const items = order?.sets?.map(
          set =>
            (set.items &&
              set.items.length !== 1 &&
              set.items?.map(item => item.firstItem.catalogItem)) ||
            [],
        );

        const sortedItems = items?.sort((a, b) => {
          if (a.length === 0) return 1;
          if (b.length === 0) return -1;
          return 0;
        });

        setSetsItems(sortedItems);

        order?.sets?.map(
          set =>
            set &&
            set.items?.length === 1 &&
            set.items.map(item => addSetlessItem(item.firstItem.code)),
        );

        order?.sets?.map((set, setIndex) => {
          if (set.items && set.items?.length !== 1) {
            const isCreateSetTrue = items?.[setIndex].some(i => i.createsSet);
            const isCreateSetFalse = items?.[setIndex].some(i => !i.createsSet);
            set.items?.map(item => {
              apolloClient
                .query<GetPricingForInterval, GetPricingForIntervalVariables>({
                  query: getPricingForInterval,
                  variables: {
                    catalogId: item.firstItem.catalogItem.id,
                    from: dateFrom,
                    to: dateTo,
                    set: !!(isCreateSetTrue && isCreateSetFalse),
                    season: order?.season ?? false,
                    morningPickUp: order?.morningPickUp ?? false,
                  },
                })
                .then(data => {
                  setSetsPrices(prevState => {
                    let newPrices;
                    let newBails;
                    const pricing = data.data.getPricingForInterval;
                    if (prevState[set.id]) {
                      newPrices = [...prevState[set.id].prices, pricing.price];
                      newBails = [...prevState[set.id].bails, pricing.bail];
                    } else {
                      newPrices = [pricing.price];
                      newBails = [pricing.bail];
                    }
                    return {
                      ...prevState,
                      [set.id]: {
                        prices: newPrices,
                        bails: newBails,
                      },
                    };
                  });
                });
            });
          }
        });
      }
    },
  });

  useEffect(() => {
    let totalPrices = 0;
    let totalBails = 0;

    Object.values(setsPrices).forEach(set => {
      totalPrices += set?.prices?.reduce((acc, price) => acc + price, 0);
      totalBails += set?.bails?.reduce((acc, bail) => acc + bail, 0);
    });

    setlessItems.forEach(item => {
      totalPrices += item.priceForInterval;
      totalBails += item.bailForInterval;
    });

    setTotalPrice({
      bailSum: totalBails,
      priceSum: order?.discount?.discount
        ? Math.round((totalPrices / 100) * (100 - order?.discount?.discount))
        : totalPrices,
    });
  }, [setsPrices, setlessItems]);

  const handleModalSetOpen = () => {
    setModalSetOpen(true);
  };

  const handleModalSetClose = () => {
    setModalSetOpen(false);
    handleModalError(null);
  };

  const handleModalSetlessItemOpen = () => {
    setModalSetlessItemOpen(true);
  };

  const handleModalSetlessItemClose = () => {
    setModalSetlessItemOpen(false);
    handleModalError(null);
  };

  const handleModalItemOpen = (setIndex: number) => {
    setModalItemOpen(prevState => ({
      ...prevState,
      [setIndex]: true,
    }));
  };

  const handleModalItemClose = (setIndex: number) => {
    setModalItemOpen(prevState => ({
      ...prevState,
      [setIndex]: false,
    }));
    handleModalError(null);
  };

  const handleModalError = (text: string | null) => {
    if (text === null) {
      setErrorState({ error: false, helperText: null });
    } else {
      setErrorState({
        error: true,
        helperText: text,
      });
    }
  };

  const modalStockCodeValidation = (input: string) => {
    if (input.length > 0) {
      const number = Number(input);
      const isValidNumber = !isNaN(number);
      setErrorState({
        error: !isValidNumber,
        helperText: isValidNumber ? null : 'Zadejte prosím validní číslo',
      });

      if (isValidNumber) {
        setModalStockCode(input);
        return input;
      }
    } else {
      setLikeStockCodes([]);
    }
  };

  const order = data.data?.order;

  const dateFrom: moment.Moment = moment(order?.from);
  const dateTo: moment.Moment = moment(order?.to);

  const orderSets = order?.sets?.filter(
    set => set.items && set.items.length > 1,
  );

  const [state, setState] = useState<OrderChangeSets>({
    sets: (orderSets || []).reduce((acc: OrderChangeSet[], set) => {
      if (set && set.items && set.items.length !== 1) {
        acc.push({
          id: set.id,
          setPriceName: set.category || '',
          stockCodes: set.items.map(item => [item.firstItem.code]) || [],
          changed: false,
        });
      }
      return acc;
    }, []),
    setsToRemove: [],
    setlessItems: [],
  });

  const { data: setPrices } = useQuery<GetSetPrices>(getSetPrices, {
    fetchPolicy: 'cache-and-network',
    skip: !order,
  });

  const [refetchStockitemByCode] = useLazyQuery<
    FindStockItemQuery,
    FindStockItemVariables
  >(findStockItem, {
    onCompleted: async data => {
      if (data.findStockItem) {
        const item: SetCatalogItem | undefined = {
          id: data.findStockItem.catalogItem.id,
          name: data.findStockItem.catalogItem.name,
          variant: data.findStockItem.catalogItem.variant,
          producer: data.findStockItem.catalogItem.producer,
          categoryName: data.findStockItem.catalogItem.categoryName,
          size: data.findStockItem.catalogItem.size,
          createSet: data.findStockItem.catalogItem.createsSet,
          pair: data.findStockItem.catalogItem.pair,
        };
        let isValidInSet = true;
        if (setsItems) {
          const isCreateSet = setsItems[currentSetAddIndex.current].some(
            i => i.createSet,
          );
          const addedItemIsCreateSetTrue =
            data.findStockItem.catalogItem.createsSet;
          isValidInSet = !(isCreateSet && addedItemIsCreateSetTrue);
        }

        if (!isValidInSet) {
          enqueueSnackbar(`Set již obsahuje primární položku.`, {
            variant: 'error',
          });
          setModalStockCode('');
          return;
        }
        setItemToAdd(item);
        refetchCanCatalogItemBeInSet({
          variables: {
            setId: currentSetAddId.current || 0,
            catalogId: item.id,
          },
        });
      } else {
        enqueueSnackbar(`Předmět s kódem (${modalStockCode}) nebyl nalezen`, {
          variant: 'error',
        });
        setModalStockCode('');
      }
    },
    fetchPolicy: 'network-only',
  });

  const [refetchCanCatalogItemBeInSet] = useLazyQuery<
    CanCatalogItemBeInSet,
    CanCatalogItemBeInSetVariables
  >(canCatalogItemBeInSet, {
    onCompleted: data => {
      const canBeInSet = data.canCatalogItemBeInSet;
      const item = itemToAdd;
      if (item) {
        if (canBeInSet) {
          const tempState = [...state.sets];
          const tempSetsItems = setsItems;
          if (tempSetsItems?.[currentSetAddIndex.current]) {
            tempSetsItems[currentSetAddIndex.current]?.push(item);
          }
          tempState[currentSetAddIndex.current].stockCodes.push([modalStockCode]);
          setState(prevState => ({
            ...prevState,
            sets: tempState,
          }));
          tempState[currentSetAddIndex.current].changed = true;

          const isCreateSetTrue = tempSetsItems?.[currentSetAddIndex.current].some(
            i => i.createSet,
          );
          const isCreateSetFalse = tempSetsItems?.[currentSetAddIndex.current].some(
            i => !i.createSet,
          );

          apolloClient
            .query<GetPricingForInterval, GetPricingForIntervalVariables>({
              query: getPricingForInterval,
              variables: {
                catalogId: item.id,
                from: dateFrom,
                to: dateTo,
                set: !!(isCreateSetTrue && isCreateSetFalse),
                season: order?.season ?? false,
                morningPickUp: order?.morningPickUp ?? false,
              },
            })
            .then(data => {
              setSetsPrices(prevState => {
                let newPrices;
                let newBails;
                const pricing = data.data.getPricingForInterval;
                if (prevState[currentSetAddId.current]) {
                  newPrices = [
                    ...prevState[currentSetAddId.current].prices,
                    pricing.price,
                  ];
                  newBails = [
                    ...prevState[currentSetAddId.current].bails,
                    pricing.bail,
                  ];
                } else {
                  newPrices = [pricing.price];
                  newBails = [pricing.bail];
                }
                return {
                  ...prevState,
                  [currentSetAddId.current]: {
                    prices: newPrices,
                    bails: newBails,
                  },
                };
              });
            });

          setSetsItems(tempSetsItems);
          setIsLocalyChanged(true);
          setModalStockCode('');
        } else {
          enqueueSnackbar(
            `Předmět (${item.id}) není možno přidat do daného setu`,
            { variant: 'error' },
          );
          setModalStockCode('');
        }
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const [refetchStockItemsLike] = useLazyQuery<
    FindStockItemsLikeQuery,
    FindStockItemsLikeVariables
  >(findStockItemsLike, {
    onCompleted: data => {
      setLikeStockCodes(data.findStockItemsLike.map(stock => stock?.code));
    },
    fetchPolicy: 'cache-and-network',
  });

  const [refetchStockPairItemsLike] = useLazyQuery<
    FindStockPairItemsLikeQuery,
    FindStockPairItemsLikeVariables
  >(findStockPairItemsLike, {
    onCompleted: data => {
      setLikePairCodes(data.findStockPairItemsLike.map(stock => stock?.code));
    },
    fetchPolicy: 'cache-and-network',
  });

  const [changeOrderQuery] = useMutation<ChangeOrder, ChangeOrderVariables>(
    changeOrder,
    {
      onError: err =>
        enqueueSnackbar(getGraphqlErrorMessage(err), { variant: 'error' }),
    },
  );

  const addSetlessItem = (stockCode: string) => {
    apolloClient
      .query<
        FindStockItemPriceIntervalQuery,
        FindStockItemPriceIntervalVariables
      >({
        query: findStockItemPriceInterval,
        variables: {
          code: stockCode,
          from: dateFrom,
          to: dateTo,
          set: false,
          season: order?.season ?? false,
          morningPickUp: order?.morningPickUp ?? false,
          deleted: false,
        },
      })
      .then(findStockItemPriceIntervalQueryData => {
        const response =
          findStockItemPriceIntervalQueryData.data.findStockItemPriceInterval;
        if (response) {
          const item: SetlessItemType = {
            id: response.catalogItem.id,
            code: response.code,
            name: response.catalogItem.name,
            variant: response.catalogItem.variant,
            producer: response.catalogItem.producer,
            categoryName: response.catalogItem.categoryName ?? '',
            size: response.catalogItem.size,
            priceForInterval: response.price,
            bailForInterval: response.bail,
            pair: response.catalogItem.pair,
          };
          setState(prevState => {
            if (!prevState.setlessItems.flat().includes(item.code)) {
              return {
                ...prevState,
                setlessItems: [...prevState.setlessItems, [item.code]],
              };
            }
            return prevState;
          });

          // TODO unique
          setSetlessItems(prevState => [...prevState, item]);
        }
      });
  };

  const debounceSearchCodeLike = useCallback(
    debounce(
      (input: string) => {
        const codeInput = modalStockCodeValidation(input)?.toString();
        if (codeInput) {
          refetchStockItemsLike({
            variables: {
              codeLike: codeInput,
              deleted: false,
            },
          });
        }
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  );

  const debounceSearchPairCodeLike = useCallback(
    debounce(
      (catalogId: number, input: string, currentCode: string) => {
        const codeInput = modalStockCodeValidation(input)?.toString();
        if (codeInput) {
          // TODO refactor to apollo to accept "currentCode"
          refetchStockPairItemsLike({
            variables: {
              catalogId: catalogId,
              codeLike: codeInput,
              deleted: false,
            },
          });
        }
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  );

  const handleAddSet = () => {
    if (modalSetValue === '' || errorState.error) {
      return;
    }

    const tempState = [...state.sets];
    tempState.push({
      id: -1,
      setPriceName: modalSetValue,
      stockCodes: [],
      changed: true,
    });
    setState(prevState => ({
      ...prevState,
      sets: tempState,
    }));
    setIsLocalyChanged(true);
  };

  useEffect(() => {
    if (!setsItems || !state.sets) {
      return;
    }
    let isDisabled = false;
    for (let i = 0; i < Math.min(state.sets.length, setsItems.length); i++) {
      const codesArray = state.sets[i].stockCodes;

      for (let j = 0; j < codesArray.length; j++) {
        const stockCode = codesArray[j];
        const pairValue = setsItems[i][j]?.pair;
        if (pairValue) {
          if (stockCode.length !== 2) {
            isDisabled = true;
            continue;
          }
        } else {
          if (stockCode.length !== 1) {
            isDisabled = true;
            continue;
          }
        }
        isDisabled = false;
      }
    }

    setIsSubmitDisabled(isDisabled);
  }, [state, setsItems]);

  useEffect(() => {
    if (!setlessItems || !state.sets) {
      return;
    }
    let isDisabled = false;
    for (
      let i = 0;
      i < Math.min(state.setlessItems.length, setlessItems.length);
      i++
    ) {
      const codesArray = state.setlessItems[i];

      for (let j = 0; j < codesArray.length; j++) {
        const stockCode = codesArray;
        const pairValue = setlessItems[i]?.pair;
        if (pairValue) {
          if (stockCode?.length !== 2) {
            isDisabled = true;
            continue;
          }
        } else {
          if (stockCode?.length !== 1) {
            isDisabled = true;
            continue;
          }
        }
        isDisabled = false;
      }
    }
    setIsSubmitSetLessDisabled(isDisabled);
  }, [state, setlessItems]);

  const handleAddItem = (setIndex: number, setId: number) => {
    if (modalStockCode === '') {
      handleModalError('Při zadání čísla se něco nepovedlo');
      return;
    }

    if (state.sets[setIndex].stockCodes.flat().includes(modalStockCode)) {
      handleModalError('Položka už v daném setu existuje');
      return;
    }

    if (errorState.error) {
      return;
    }

    currentSetAddIndex.current = setIndex;
    currentSetAddId.current = setId;

    refetchStockitemByCode({
      variables: {
        code: modalStockCode,
        deleted: false,
      },
    });
  };

  const handleAddSetlessItem = () => {
    if (modalStockCode === '') {
      handleModalError('Při zadání čísla se něco nepovedlo');
      return;
    }

    if (state.setlessItems.flat().includes(modalStockCode)) {
      handleModalError('Položka už existuje');
      return;
    }

    if (errorState.error) {
      return;
    }

    addSetlessItem(modalStockCode);
    setIsLocalyChanged(true);
  };

  const handleAddPair = (setIndex: number, itemIndex: number) => {
    if (pairStockCode === '') {
      handleModalError('Při zadání čísla se něco nepovedlo');
      return;
    }

    if (state.sets[setIndex].stockCodes.flat().includes(pairStockCode)) {
      handleModalError('Položka už v daném setu existuje');
      return;
    }

    const tempStateSets = [...state.sets];
    tempStateSets[setIndex].stockCodes[itemIndex].push(pairStockCode);
    setState(prevState => ({
      ...prevState,
      sets: tempStateSets,
    }));
    tempStateSets[setIndex].changed = true;
    setIsLocalyChanged(true);
  };

  const handleAddSetlessPair = (setlessItem: SetlessItemType) => {
    const setlessItemIndex  = getSetlessCodeIndex(setlessItem);
    if (pairStockCode === '') {
      handleModalError('Při zadání čísla se něco nepovedlo');
      return;
    }

    if (state.setlessItems.flat().includes(pairStockCode)) {
      handleModalError('Položka už v daném setu existuje');
      return;
    }

    const tempStateSetlessItems = [...state.setlessItems];
    tempStateSetlessItems?.[setlessItemIndex].push(pairStockCode);
    setState(prevState => ({
      ...prevState,
      setlessItems: tempStateSetlessItems,
    }));
    setIsLocalyChanged(true);
  };

  const handleDeleteSet = (setIndex: number, setId: number) => {
    if (state.sets) {
      const tempSets = [...state.sets];
      const tempSetsToRemove = [...state.setsToRemove];
      const tempItems = setsItems;
      const tempSetsPrices = { ...setsPrices };
      if (tempSets[setIndex]) {
        tempSets.splice(setIndex, 1);
        tempSetsToRemove.push(setIndex);
        tempItems?.splice(setIndex, 1);
      }
      delete tempSetsPrices[setId];
      setSetsPrices(tempSetsPrices);
      setState(prevState => ({
        ...prevState,
        sets: tempSets,
        setsToRemove: tempSetsToRemove,
      }));
      setSetsItems(tempItems);
      setIsLocalyChanged(true);
      order?.sets?.splice(setIndex, 1);
    }
  };

  const handleDeleteItem = (
    setIndex: number,
    itemIndex: number,
    setId: number,
  ) => {
    if (state.sets) {
      const tempSets = [...state.sets];
      const tempItems = setsItems;
      const tempSetsPrices = setsPrices;
      if (tempSets[setIndex]) {
        tempSets[setIndex].stockCodes?.splice(itemIndex, 1);
        tempSets[setIndex].changed = true;
        tempSetsPrices[setId].prices.splice(itemIndex, 1);
        tempSetsPrices[setId].bails.splice(itemIndex, 1);
      }
      setState(prevState => ({
        ...prevState,
        sets: tempSets,
      }));
      setSetsPrices(prevState => ({
        ...prevState,
        [setId]: tempSetsPrices[setId],
      }));
      if (tempItems?.[setIndex]) {
        tempItems[setIndex]?.splice(itemIndex, 1);
      }
      setSetsItems(tempItems);
      setIsLocalyChanged(true);
    }
  };

  const handleDeleteSetlessItem = (setlessItem: SetlessItemType) => {
    const itemIndex  = getSetlessCodeIndex(setlessItem);

    if (state.setlessItems[itemIndex]) {
      const tempSetlessItems = [...setlessItems];
      const tempStateSetlessItems = [...state.setlessItems];
      tempSetlessItems.splice(itemIndex, 1);
      tempStateSetlessItems.splice(itemIndex, 1);
      setSetlessItems(tempSetlessItems);
      setState(prevState => ({
        ...prevState,
        setlessItems: tempStateSetlessItems,
      }));
      setIsLocalyChanged(true);
    }
  };

  const handleSave = async () => {
    setIsSubmitDisabled(true);
    if (isLocalyChanged) {
      try {
        const res = await changeOrderQuery({
          variables: {
            orderId: orderId,
            sets: {
              sets: state.sets,
              setsToRemove: state.setsToRemove,
              setlessItems: state.setlessItems,
            },
          },
        });

        if (res) {
          await new Promise(resolve => setTimeout(resolve, 2000));

          window.location.reload();
        }
      } catch (error) {
        console.error('Error saving order:', error);
      }
    } else {
      enqueueSnackbar('Nebyly provedeny zadne zmeny na dane objednavce', {
        variant: 'error',
      });
    }
  };

  if (invalidOrderId) {
    return <div>Not found</div>;
  }

  if (data.loading) {
    return <p>Loading...</p>;
  }
  if (data.error) {
    return <p>Error :</p>;
  }
  if (!order) {
    return <p>Failed to load data :</p>;
  }

  const getSetlessCodeIndex = (statelessItem: SetlessItemType) =>  {
    return state.setlessItems.findIndex(codes => {
      return codes.some( code => code === statelessItem.code)
    });
  }

  const getSetlessCodes = (statelessItem: SetlessItemType) =>  {
    const statelessItemIndex = getSetlessCodeIndex(statelessItem);
    return state.setlessItems[statelessItemIndex]?.filter(notEmpty) || [];
  }

  return (
    <Box className={classes.container}>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.boldCell}>Číslo zakázky</TableCell>
              <TableCell>{order.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.boldCell}>Nájemce</TableCell>
              <TableCell>{order.customer?.name ?? 'Nevyplněno'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.boldCell}>Telefon</TableCell>
              <TableCell>{order.customer?.phone ?? 'Nevyplněno'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.boldCell}>E-mail</TableCell>
              <TableCell>{order.customer?.email ?? 'Nevyplněno'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Paper className={classes.paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                Termín nájmu od <b>{moment(order.from).format('DD.MM.YYYY')}</b>{' '}
                do <b>{moment(order.to).format('DD.MM.YYYY')}</b>
              </TableCell>
            </TableRow>
            {!!order.sets?.length && (
              <TableRow>
                <TableCell className={classes.boldCell} align="left">
                  Předmět nájmu
                </TableCell>
                <TableCell className={classes.boldCell} align="center">
                  Cena
                </TableCell>
                <TableCell className={classes.boldCell} align="center">
                  Kauce
                </TableCell>
                <TableCell className={classes.boldCell} align="right">
                  Akce
                </TableCell>
              </TableRow>
            )}
            {state.sets?.map((set, setIndex) => (
              <Fragment key={setIndex}>
                <TableRow
                  id={`set-${setIndex}`}
                  className={
                    set.changed ? classes.setChanged : classes.setUnchanged
                  }
                >
                  <TableCell className={classes.boldCell} align="left">
                    {set?.setPriceName}
                  </TableCell>
                  {!set.changed && (
                    <>
                      <TableCell align="right">
                        {setsPrices[set.id]
                          ? setsPrices[set.id]?.prices?.reduce(
                              (acc: number, sum: number) => acc + sum,
                              0,
                            )
                          : 0}
                        Kč
                      </TableCell>
                      <TableCell align="right">
                        {setsPrices[set.id]
                          ? setsPrices[set.id]?.bails?.reduce(
                              (acc: number, sum: number) => acc + sum,
                              0,
                            )
                          : 0}
                        Kč
                      </TableCell>
                    </>
                  )}

                  <TableCell align="right">
                    <Tooltip title="Smazat set">
                      <DeleteIcon
                        className="icon"
                        onClick={() => {
                          handleDeleteSet(setIndex, set.id);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <Table
                  id={`set-${setIndex}_table`}
                  className={classes.setItemsWrapper}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.boldCell} align="left">
                        Kód položky
                      </TableCell>
                      <TableCell className={classes.boldCell} align="left">
                        Název položky
                      </TableCell>
                      <TableCell className={classes.boldCell} align="left">
                        Varianta položky
                      </TableCell>
                      <TableCell className={classes.boldCell} align="left">
                        Značka položky
                      </TableCell>
                      <TableCell className={classes.boldCell} align="left">
                        Rozměry položky
                      </TableCell>
                      <TableCell className={classes.boldCell} align="right">
                        Akce
                      </TableCell>
                    </TableRow>
                    {setsItems?.[setIndex]?.map((item, itemIndex) => (
                      <TableRow
                        id={`set-${setIndex}_item-${itemIndex}`}
                        className={classes.setItems}
                      >
                        <TableCell>
                          {state.sets[setIndex].stockCodes[itemIndex]?.join(
                            ', ',
                          )}
                        </TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.variant}</TableCell>
                        <TableCell>{item?.producer}</TableCell>
                        <TableCell>{item?.size}</TableCell>
                        {(setsItems?.[setIndex][itemIndex].pair && state.sets[setIndex].stockCodes[itemIndex]?.length < 2) && (
                          <>
                            <TableCell>
                              <Autocomplete
                                id="pair-autocomplete"
                                options={likePairCodes}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    id="pair-item-id"
                                    label="KÓD Párové Položky"
                                    variant="outlined"
                                    size="small"
                                    error={errorState.error}
                                    helperText={errorState.helperText}
                                    onBlur={() => setLikePairCodes([])}
                                    onChange={e => {
                                      debounceSearchPairCodeLike(
                                        item.id,
                                        e.target.value,
                                        state.sets[setIndex].stockCodes[
                                          itemIndex
                                        ][0],
                                      );
                                    }}
                                    style={{ width: '20rem' }}
                                  />
                                )}
                                onChange={(event, value) => {
                                  setPairStockCode(value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Tooltip title="Přidat párovou položku">
                                <AddBoxIcon
                                  className="icon"
                                  onClick={() => {
                                    handleAddPair(setIndex, itemIndex);
                                  }}
                                />
                              </Tooltip>
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          <Tooltip title="Smazat položku">
                            <DeleteIcon
                              className="icon"
                              onClick={() => {
                                handleDeleteItem(setIndex, itemIndex, set.id);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TableRow>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        set.id === -1
                          ? 'Novou položku jde přidat po provedení změn'
                          : 'Přidat novou položku do setu'
                      }
                    >
                      <AddCircleIcon
                        className="icon"
                        onClick={() => {
                          set.id === -1
                            ? enqueueSnackbar(
                                'Novou položku jde přidat po provedení změn',
                                { variant: 'error' },
                              )
                            : handleModalItemOpen(setIndex);
                        }}
                        style={set.id === -1 ? { opacity: 0.3 } : {}}
                      />
                    </Tooltip>
                    <Modal
                      isOpen={modalItemOpen[setIndex]}
                      onClose={() => {
                        handleModalItemClose(setIndex);
                        setModalStockCode('');
                      }}
                      aria-labelledby="add-item-modal"
                      aria-describedby="add-item-by-code-modal"
                      title={`Přidat položku do setu "${set?.setPriceName}" pomocí KÓDU`}
                    >
                      <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        <Autocomplete
                          id="combo-box-demo"
                          options={likeStockCodes}
                          renderInput={params => (
                            <TextField
                              {...params}
                              id="item-id"
                              label="KÓD Položky"
                              variant="outlined"
                              size="small"
                              error={errorState.error}
                              helperText={errorState.helperText}
                              onBlur={() => setLikeStockCodes([])}
                              onChange={e => {
                                debounceSearchCodeLike(e.target.value);
                              }}
                              style={{ width: '20rem' }}
                            />
                          )}
                          onChange={(event, value) => {
                            setModalStockCode(value);
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={async () => {
                            handleAddItem(setIndex, set.id);
                          }}
                          style={{ marginInline: '1rem' }}
                        >
                          Přidat
                        </Button>
                      </Box>
                    </Modal>
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
            {setlessItems?.map((setlessItem) => (
              <TableRow
                id={`setlessItem-${setlessItem.code}`}
                className={classes.setlessItem}
              >
                <TableCell>
                  <TableRow>
                    <TableCell
                      className={classes.boldCell}
                      colSpan={1}
                      align="left"
                    >
                      {getSetlessCodes(setlessItem).join(', ')}
                    </TableCell>
                    <TableCell
                      className={classes.boldCell}
                      colSpan={1}
                      align="left"
                    >
                      {setlessItem.name}
                    </TableCell>
                    <TableCell
                      className={classes.boldCell}
                      colSpan={1}
                      align="left"
                    >
                      {setlessItem.variant}
                    </TableCell>
                    <TableCell
                      className={classes.boldCell}
                      colSpan={1}
                      align="left"
                    >
                      {setlessItem.producer}
                    </TableCell>
                    <TableCell
                      className={classes.boldCell}
                      colSpan={1}
                      align="left"
                    >
                      {setlessItem.size}
                    </TableCell>
                    {(setlessItem.pair && getSetlessCodes(setlessItem)?.length < 2) && (
                      <>
                        <TableCell>
                          <Autocomplete
                            id="pair-autocomplete"
                            options={likePairCodes}
                            renderInput={params => (
                              <TextField
                                {...params}
                                id="pair-item-id"
                                label="KÓD Párové Položky"
                                variant="outlined"
                                size="small"
                                error={errorState.error}
                                helperText={errorState.helperText}
                                onBlur={() => setLikePairCodes([])}
                                onChange={e => {
                                  debounceSearchPairCodeLike(
                                    setlessItem.id,
                                    e.target.value,
                                    setlessItem.code ||
                                      '',
                                  );
                                }}
                                style={{ width: '20rem' }}
                              />
                            )}
                            onChange={(event, value) => {
                              setPairStockCode(value);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Přidat párovou položku">
                            <AddBoxIcon
                              className="icon"
                              onClick={() => {
                                handleAddSetlessPair(setlessItem);
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableCell>
                <TableCell align="right">
                  {setlessItem.priceForInterval}
                  Kč
                </TableCell>
                <TableCell align="right">
                  {setlessItem.bailForInterval}
                  Kč
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Smazat set">
                    <DeleteIcon
                      className="icon"
                      onClick={() => {
                        handleDeleteSetlessItem(setlessItem);
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              {!isLocalyChanged && (
                <>
                  <TableCell align="left">
                    Celkem {order.discount?.discount ? 'se slevou' : ''}
                  </TableCell>
                  <TableCell className={classes.boldCell} align="right">
                    {totalPrice.priceSum?.toLocaleString('FR')} Kč
                  </TableCell>
                  <TableCell className={classes.boldCell} align="right">
                    {totalPrice.bailSum?.toLocaleString('FR')} Kč
                  </TableCell>
                </>
              )}

              <TableCell align="right">
                <Tooltip title="Přidat nový set">
                  <AddToPhotosIcon
                    className="icon"
                    onClick={() => handleModalSetOpen()}
                  />
                </Tooltip>
                <Modal
                  isOpen={modalSetOpen}
                  onClose={() => {
                    handleModalSetClose();
                    setModalSetValue('');
                  }}
                  aria-labelledby="add-set-modal"
                  aria-describedby="add-set-by-name-modal"
                  title={`Přidat set pomocí kategorie`}
                >
                  <FormControl>
                    <InputLabel id="add-set-modal-label">Název setu</InputLabel>
                    <Select
                      labelId="add-set-by-name-select"
                      id="add-set-select"
                      value={modalSetValue}
                      onChange={e => setModalSetValue(e.target.value as string)}
                      style={{ width: '20rem' }}
                    >
                      <MenuItem value={''}>Bez výběru</MenuItem>
                      {setPrices?.getSetPrices?.map(setPrice => {
                        return (
                          <MenuItem value={setPrice?.name || ''}>
                            {setPrice?.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      handleAddSet();
                    }}
                    style={{ marginBlock: '0.8rem', marginLeft: '1rem' }}
                  >
                    Přidat
                  </Button>
                </Modal>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" colSpan={4}>
                <Tooltip title="Přidat novou položku bez setu">
                  <AddCircleOutlineIcon
                    className="icon"
                    onClick={() => handleModalSetlessItemOpen()}
                  />
                </Tooltip>
                <Modal
                  isOpen={modalSetlessItemOpen}
                  onClose={() => {
                    handleModalSetlessItemClose();
                    setModalStockCode('');
                  }}
                  aria-labelledby="add-setless-item-modal"
                  aria-describedby="add-setless-item-by-code-modal"
                  title={`Přidat položku bez setu do objednávky pomocí KÓDU`}
                >
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={likeStockCodes}
                      renderInput={params => (
                        <TextField
                          {...params}
                          id="item-id"
                          label="KÓD Položky"
                          variant="outlined"
                          size="small"
                          error={errorState.error}
                          helperText={errorState.helperText}
                          onBlur={() => setLikeStockCodes([])}
                          onChange={e => {
                            debounceSearchCodeLike(e.target.value);
                          }}
                          style={{ width: '20rem' }}
                        />
                      )}
                      onChange={(event, value) => {
                        setModalStockCode(value);
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={async () => {
                        handleAddSetlessItem();
                      }}
                      style={{ marginInline: '1rem' }}
                    >
                      Přidat
                    </Button>
                  </Box>
                </Modal>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
      <Box style={{ marginBlock: '4rem' }}>
        <Button
          variant="contained"
          color="secondary"
          href="/orders-and-reservations"
          style={{ color: 'white', marginInline: '1rem' }}
        >
          Vrátit se zpět
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginInline: '1rem' }}
          disabled={
            !isLocalyChanged || isSubmitDisabled || isSubmitSetLessDisabled
          }
        >
          Provést změny
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    paper: {
      minWidth: '900px',
      margin: '24px 24px 0px 24px',
      padding: '16px',
    },
    tableCell: {
      border: 'none',
      padding: '4px 4px 4px 8px',
    },
    boldCell: {
      fontWeight: 'bold',
    },
    tableCellName: {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
      borderBottom: 'none',
      padding: '4px 4px 4px 8px',
    },
    setUnchanged: {
      backgroundColor: 'springgreen',
    },
    setChanged: {
      backgroundColor: 'coral',
    },
    setlessItem: {
      backgroundColor: 'deepskyblue',
    },
    setItems: {
      // border: '1px solid',
    },
    setItemsWrapper: {
      // marginBlockEnd: '2rem',
    },
  }),
);

export default OrderChange;
